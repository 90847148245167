<template>
  <div>
    <div class="promocao-adicionar">
      <div class="conteudo-interno">
        <div class="nova-comunicacao-container">
          <h3 class="detalhes-ocorrencia">Detalhes da ocorrências</h3>

          <FormularioOcorrencia :editar="true" />

        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import FormularioOcorrencia from '@/components/elementos/paginas/sac/ocorrencias/FormularioOcorrencia.vue'

export default {

  name: "OcorrenciaEditar",

  components: {
    FormularioOcorrencia
  },
};
</script>
  
<style lang="scss" scoped>
.promocao-adicionar {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.detalhes-ocorrencia,
.titulo-ocorrencias {
  font-size: 24px;
  width: 100%;
  margin: 60px 10px 30px 10px;
  color: #363636;
}
</style>